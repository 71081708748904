import React from 'react'
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Cta from '../../components/cta'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

const LecebneMasazePage = ({data}) => (
  <Layout>
    <SEO title="Služby masážního salonu Vimperk" />

    <div className="container">
      <h1 className="pb-1">Léčebné masáže</h1>

      <p>
        Na začátku našeho sezení probereme Vaše potřeby a&nbsp;z&nbsp;více než
        30&nbsp;procedur vytvoříme kombinaci právě pro&nbsp;Vás.
        <br />
        Doporučujeme vyhradit&nbsp;si čas aspoň na&nbsp;hodinovou masáž.
      </p>

      <div className="uk-grid uk-child-width-expand pb-2">
        <div>
          <ul>
            <li>Masáž zad a šíje,</li>
            <li>masáž krku, šíje a&nbsp;celého ramenního pásu,</li>
            <li>masáž zad a rukou,</li>
            <li>6 technik masáží nohou.</li>
          </ul>

          <p className="price">400 Kč / 30&nbsp;minut</p>
        </div>

        <div>
          <Img fluid={data.file.childImageSharp.fluid} objectFit="cover" />
        </div>
      </div>

      <Cta before={<Link className="link" to="/sluzby">Zpět na všechny služby</Link>} />
    </div>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "bamboo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default LecebneMasazePage
