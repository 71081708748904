import React from 'react'

const Cta = ({ before = null, after = null }) => (
  <div className="">
    {before}
    <a className="btn-cta" href="tel:+420776009508">
      Volejte 776 009 508
    </a>
    {after || (before && <span>&nbsp;</span>)}
  </div>
)

export default Cta
